<template>
  <div
    :id="index"
    v-ripple="{ class: textField === 'name' ? 'primary--text' : 'transparent--text' }"
    class="fill-height d-flex flex-row align-center pa-2"
    :style="styleSettings.body"
    @click="$emit('onSelect', localItem)"
  >
    <div
      class="d-flex flex-row align-center justify-space-between"
      style="width: 66%;"
    >
      <span
        v-if="!clicked"
        class="ml-2"
        style="user-select: none;"
        v-text="label"
      />
    </div>
    <div class="d-flex flex-column justify-center ml-auto fill-height">
      <v-dialog
        v-model="clicked"
        fullscreen
        transition="fade-transition"
        overlay-color="black"
        overlay-opacity="1"
      >
        <div data-fullscreen>
          <div
            class="d-flex flex-row align-center pa-2"
            :style="styleSettings.editDialog"
          >
            <v-text-field
              v-if="clicked"
              :key="textFieldKey"
              v-model="label"
              v-on-clickaway="clickedAway"
              color="primary"
              clearable
              class="px-2"
              :autofocus="true"
              :placeholder="$t('project.academy.categories.insertName')"
              @keyup.enter="label && $emit('onUpdate', { ...localItem, ...({ [textField]: label }) }); clicked = false"
              @keydown.esc="!label && $emit('onDelete', localItem)"
            />
          </div>
          <v-btn
            :disabled="isPublished"
            class="mx-2"
            fab
            dark
            small
            color="primary"
            :style="styleSettings.editDialog.deleteButton"
            @click="$emit('onDelete', localItem)"
          >
            <v-icon dark>
              delete_outline
            </v-icon>
          </v-btn>
          <v-btn
            depressed
            color="primary"
            :style="styleSettings.editDialog.saveButton"
            @click="$emit('onUpdate', { ...localItem, ...({ [textField]: label }) }); clicked = false"
          >
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-dialog>
      <v-menu v-if="label">
        <template #activator="{ on, attrs }">
          <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="!isPublished" @click="$emit('onDelete', localItem)">
            <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleClicked()">
            <v-list-item-title>{{ $t('common.modify') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CategoryListItem',
  mixins: [clickaway],
  props: {
    textField: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localItem: {},
      label: null,
      textFieldKey: 0,
      clicked: false,
      currentElementPosition: {},
    }
  },
  computed: {
    ...mapGetters({ isPublished: 'project/isPublished' }),
    styleSettings() {
      return {
        body: {
          cursor: this.textField === 'name' && 'pointer',
        },
        editDialog: {
          position: 'relative',
          width: `${this.currentElementPosition.width}px`,
          height: `${this.currentElementPosition.height}px`,
          top: `${this.currentElementPosition.top}px`,
          left: `${this.currentElementPosition.left}px`,
          background: 'white',
          border: '2px solid #dadada',
          borderRadius: '12px',
          deleteButton: {
            position: 'relative',
            height: `${this.currentElementPosition.height}px`,
            width: `${this.currentElementPosition.height}px`,
            top: `${this.currentElementPosition.top - this.currentElementPosition.height}px`,
            left: `${this.currentElementPosition.left + this.currentElementPosition.width + 16}px`,
          },
          saveButton: {
            position: 'relative',
            width: `${this.currentElementPosition.width / 2}px`,
            top: `${this.currentElementPosition.top + 16}px`,
            left: `${this.currentElementPosition.left - 60}px`,
          },
        },
      }
    },
    isNewItem({ localItem }) {
      return !localItem[this.textField]
    },
  },
  created() {
    this.localItem = this.item
    this.label = this.item[this.textField]
    if (this.isNewItem) {
      this.$nextTick(() => this.toggleClicked())
    }
  },
  methods: {
    clickedAway() {
      if (this.isPublished) {
        this.label = this.label || this.item[this.textField]
        this.clicked = false
      }
      if (this.isNewItem) this.clicked = false
      if (!this.label) this.$emit('onDelete', this.localItem)
    },
    toggleClicked() {
      // eslint-disable-next-line no-unused-expressions
      this.clicked = true
      this.textFieldKey++
      this.currentElementPosition = document.getElementById(this.index).getBoundingClientRect()
    },
  },
}
</script>

<style scoped>
[data-fullscreen] {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
